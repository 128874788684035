import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__filled-track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__range-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__filled-track:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$interactive-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__track:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p><em parentName="p">{`Slider`}</em>{` labels should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized, and no more than three words.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__range-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The width of a slider varies based on page content and layout.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__thumb:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20 / 1.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 / 0.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-left, margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider__range-label:last-of-type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABb0lEQVQoz2NgAIIpQf8ZwHTwf4bZ0f8ZerSUGRZE1DPsdS9iWJdUxzCxo5uho7uLYcWqVWB1Fx4/YXj3/j3TuwdPGUH851uPg8UvqqcywEF/+D8GSsBpFl8EpzXoGoQOviVVH7ZHbZKmtsBW52LBA84FvOsTazlm1razdLe2s6yev5iFgY2P6dqTp5JAFwa8u/9UE6Tv6bK9jCguTInbD6bTko6JhJauUc8wsVZtLKsXmtTXzz1h6mTe1lmTuetmT+CaumUNL0NuqvDGWzcjX75+/fL1vcdlIH2P5m9nQTFwjhWU1mNg9In05/zPPYvlDVc0+33+aPaHXJHsD3mi2F/xRHMckonjLPDIEUzzzxG4VbuA+0ZKHxvMlxe10hFevqbYBaavqHYx/me4zHBYK4HznFYa73mtNJ7z2um8QMwF4l/STON+ppjC80Mwlh2kPhOI7yb2MZxiMMWMlP8MblDGf6Ii4RdQ3X+o2v9IegDaoI/QMgkdLgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for slider",
          "title": "Structure and spacing measurements for slider",
          "src": "/static/473a605dc913f4c6d40a5a65016601e6/fb070/slider-style-1.png",
          "srcSet": ["/static/473a605dc913f4c6d40a5a65016601e6/d6747/slider-style-1.png 288w", "/static/473a605dc913f4c6d40a5a65016601e6/09548/slider-style-1.png 576w", "/static/473a605dc913f4c6d40a5a65016601e6/fb070/slider-style-1.png 1152w", "/static/473a605dc913f4c6d40a5a65016601e6/c3e47/slider-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <p>{`The following specs are not built into the slider component but are recommended by design as the proper sizing for the slider tracking line.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider-track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 / 12.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--slider-track`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`max-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`640 / 40`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      